

// const ADD_URL = URL_APP + "api/v1/rincian_obyek/";

  import Joi from "joi";
  const schema = Joi.object().keys({
    username: Joi.string().regex(/^[a-zA-Z0-9_]*$/).min(3).max(16).required(),
    password: Joi.string().min(6).required(),
  });

  export default {
    name: "monev_login",
    data : function(){
      return {

          errorMessage: '',
          user : {
            username : "",
            password : ""
          },
          form : {
            id : '',
            username : "",
            nama : '',
            hp: "",
            file: "",
            email: "",
            password : "",
            confirmPassword: "",
          },
          mdl_opsi : false,
          mdl_perusahaan : false,
          mdl_add : false,
          btn_add: false,
          btn_perusahaan: false,

          url : {
            LOGIN_URL : this.$store.state.url.URL_APP + "auth/login",
            SIGNUP_URL : this.$store.state.url.URL_APP + "auth/signup",
            SIGNUPXXX_URL : this.$store.state.url.URL_APP + "auth/signupxxx",
          }
      }
    },
    watch: {
      user: {
          handler() {
              this.errorMessage = "";
          },
          deep: true
      }
    },
    methods: {
      // go_register : function(){
      //   alert(App.data().sesseee);
      //   this.displayed = 'block';
      // },
      btn_login : function(){
        this.errorMessage = '';
        if(this.validUser()){
          this.$store.commit("shoWLoading");
          // this.$q.notify("hi");

          // this.$store.commit("shoWNotify", 'Kiken', 'primary', 'timer');
          const body = {
            username : this.user.username,
            password : this.user.password
          }
          fetch(this.url.LOGIN_URL, {
            method : 'POST',
            headers : {
              'content-type' : 'application/json',
            },
            body : JSON.stringify(body),
          }).then((response) => {
            
            // console.log(response);
            
                if (response.ok) {
                    return response.json();
                    this.$store.commit("shoWLoading");
                }

                return response.json().then(error => {
                    throw new Error(error.message);
                    this.$store.commit("shoWLoading");
                });
            })
            .then((result) => {
              console.log("=============================");
              // console.log(result);
              // menyimpan token yang tergenerate dari server
              localStorage.token = result.token;
              localStorage.profile = JSON.stringify(result.profile);
              setTimeout(() => {
                this.$store.commit("hideLoading")
                this.$router.push('/');
                // location.reload();
              }, 1000);
            })
            .catch(error => {
              setTimeout(() => {
                this.$store.commit("hideLoading")
                this.errorMessage = error.message;
              }, 1000);
            });;
        }



      },
      
      addData: function() {
        this.errorMessage = "";
          if (this.validasiUser()) {
              // Jika user sdh valid lakukan pengiriman data ke server
              const body = {
                username: this.form.username,
                password: this.form.password
              };
              this.signingUp = true;
              fetch(this.url.SIGNUP_URL, {
                  method: "POST",
                  body: JSON.stringify(this.form),
                  headers: {
                      "content-type": "application/json",
                      // authorization: "kikensbatara " + localStorage.token
                  },
              }).then((response) => {
                  this.signingUp = false;
                  if (response.ok) {
                      this.Notify('Berhasil Melakukan Registrasi', 'secondary', 'check_circle_outline');
                      // this.getView();
                      // this.$router.push('/login');
                      // return response.json();
                  }
                  else{
                    return response.json().then(error => {
                      throw new Error(error.message);
                    });

                  }
              })
              .catch((error) => {
                setTimeout(() => {
                  this.signingUp = false;
                  this.errorMessage = error.message;
                }, 1000);
              });
          }else{
            console.log("tidak valid");

          }

      },

      addDataXXX: function() {
        this.errorMessage = "";
          if (this.validasiUser()) {
              // Jika user sdh valid lakukan pengiriman data ke server
              const body = {
                username: this.form.username,
                password: this.form.password
              };
              this.signingUp = true;
              fetch(this.url.SIGNUPXXX_URL, {
                  method: "POST",
                  body: JSON.stringify(this.form),
                  headers: {
                      "content-type": "application/json",
                      // authorization: "kikensbatara " + localStorage.token
                  },
              }).then((response) => {
                  this.signingUp = false;
                  if (response.ok) {
                      this.Notify('Berhasil Melakukan Registrasi', 'secondary', 'check_circle_outline');
                      // this.getView();
                      // this.$router.push('/login');
                      // return response.json();
                  }
                  else{
                    return response.json().then(error => {
                      throw new Error(error.message);
                    });

                  }
              })
              .catch((error) => {
                setTimeout(() => {
                  this.signingUp = false;
                  this.errorMessage = error.message;
                }, 1000);
              });
          }else{
            console.log("tidak valid");
          }

      },

      validUser: function(){
        const result = Joi.validate(this.user, schema);
        if (result.error === null) {
          return true;
        }
        if (result.error.message.includes("username")) {
          this.errorMessage = "Username tidak valid";
        } else {
          this.errorMessage = "Username tidak valid2";
        }
        return false;
      },

      validasiUser : function(){
        if (this.form.password !== this.form.confirmPassword) {
          this.errorMessage = " Password dan Confirm Password harus sama.!";
            // this.Notify('Password dan Confirm Password harus sama.!', 'accent', 'clear');
            return false;
        }
        // Memulai proses validasi melalui skema Joi yang sebelumnya dibuat
        // req.body merupakan data yang di kirim oleh client dan schema merupakan skema joi yg di buat sebelumnya
        const body = {
                username: this.form.username,
                password: this.form.password
        };


        const result = schema.validate(body);

        console.log(result.error)

        if (result.error == null || result.error ==undefined) {
            return true;
        }

        if (result.error.message.includes("username")) {
            // jika pesan error yang dihasilkan mengandung char "username" maka
            this.errorMessage = " Username tidak valid (Min : 6 dan Max : 14 Karakter)";
          // this.Notify('Username tidak valid (Min : 6 dan Max : 14 Karakter)', 'accent', 'clear');
        } else {
          
            // this.Notify('Password tidak valid (Min : 6 Karakter)', 'accent', 'clear');
            this.errorMessage = " Password tidak valid (Min : 6 Karakter)";
          //   console.log(result.error);

        }
        return false;
      },

      Notify : function(message, positive, icon){
        this.$q.notify({
          message: message,
          color: positive,
          icon: icon,
          position : 'top',
          timeout: 500,
        })
      },
    },
    mounted : function(){

      
      

    },
  };
